<template>
  <v-row>
    <v-col v-for="curso in cursos" :key="curso.id" cols="12" sm="6" md="4">
      <v-skeleton-loader v-if="loading" :elevation="1" type="card"></v-skeleton-loader>
      <v-card class="h-100 d-flex flex-column">
        <v-img height="200" cover :src="curso.imagen_url"></v-img>
        <v-card-title>{{ curso.titulo }}</v-card-title>
        <v-card-text>{{ curso.descripcion }}</v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-btn color="primary" :to="`/curso/${curso.id}`" text>{{
      t('Acceder') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
  <!-- Diálogo para Solicitar Acceso -->
  <v-dialog v-model="dialog" max-width="600px">
    <v-card>
      <v-card-title class="text-h5">{{ t('Quieres_acceder_a_la_actividad') }} {{ cursoSeleccionado.titulo
        }}?</v-card-title>
      <v-card-text>
        <span v-if="mensajeExperiencia">{{ mensajeExperiencia }}<br><br></span>
        {{ t('Para_acceder_a_la_actividad') }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">{{ t('Cerrar') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import { axios } from '../axios';
import { useI18n } from 'vue-i18n';
export default {
  setup() {
    const { t } = useI18n();
    return {
      t
    };
  },
  data() {
    return {
      cursos: [
        {
          "id": 1
        },
        {
          "id": 2
        },
        {
          "id": 3
        }
      ],
      dialog: false,
      cursoSeleccionado: {},
      mensajeExperiencia: '',
      loading: true,
    }
  },
  methods: {
    async fetchCursos() {
      this.loading = true
      try {
        const response = await axios.get(`apren/`, {
        });
        this.cursos = response.data;
      } catch (error) {
        console.error("Error al obtener los cursos:", error);
      }
      this.loading = false
    },
    solicitarAcceso(cursoId) {
      this.cursoSeleccionado = this.cursos.find(curso => curso.id === cursoId);

      // Usar claves de traducción
      if (this.cursoSeleccionado.experiencia_minima === 0 && this.cursoSeleccionado.experiencia_maxima) {
        this.mensajeExperiencia = this.t('mensaje_para_residentes_con_menos_experiencia', { experiencia_maxima: this.cursoSeleccionado.experiencia_maxima });
      } else if (this.cursoSeleccionado.experiencia_minima && !this.cursoSeleccionado.experiencia_maxima) {
        this.mensajeExperiencia = this.t('mensaje_para_residentes_con_al_menos_experiencia', { experiencia_minima: this.cursoSeleccionado.experiencia_minima });
      } else if (this.cursoSeleccionado.experiencia_minima && this.cursoSeleccionado.experiencia_maxima) {
        this.mensajeExperiencia = this.t('mensaje_para_residentes_con_rango_experiencia', { experiencia_minima: this.cursoSeleccionado.experiencia_minima, experiencia_maxima: this.cursoSeleccionado.experiencia_maxima });
      } else {
        this.mensajeExperiencia = '';
      }
      this.dialog = true;
    },

  },
  created() {
    this.fetchCursos()
  }
}
</script>
