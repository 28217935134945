<template>
    <v-col v-if="!navbarra && !IDcuestionario" cols="12">
        <v-card class="mx-4">
            <!-- Button for 'mirar' option -->
            <v-btn block color="primary" v-if="selectedOption === 'editar'" @click="openDialog('crear')">
                {{ t('Crear') }} {{ t('cuestionario') }}
            </v-btn>
        </v-card>
    </v-col>
    <template v-if="IDcuestionario">
        <v-col v-if="!navbarra" cols="12">
            <v-card class="mx-4">
                <!-- Button for 'mirar' option -->
                <v-btn block color="primary" v-if="selectedOption === 'mirar'" @click="accederCuestionario">
                    {{ t('Ponte_a_prueba') }}
                </v-btn>
                <!-- Button for 'editar' option -->
                <v-btn block color="primary" v-if="selectedOption === 'editar'" @click="openPreguntasDialog">
                    {{ t('Editar') }} {{ t('preguntas') }}
                </v-btn>
            </v-card>
        </v-col>
        <template v-if="navbarra">
            <v-btn v-if="selectedOption === 'mirar'" :icon="mdiIcon" :color="starColor" variant="text"
                @click="accederCuestionario"></v-btn>
            <v-btn v-if="selectedOption === 'editar'" icon="mdi-star" color="primary" variant="text"
                @click="openPreguntasDialog"></v-btn>
        </template>
    </template>

    <v-dialog v-model="overlay" class="align-center justify-center" persistent fullscreen scrollable>
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>{{ cuestionario.titulo }}</v-toolbar-title>
                <v-btn icon @click="cerrarOverlay">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-title v-if="alumnoSeleccionado.nombreCompleto && selectedOptionVista === 'docente'">{{ t('Evaluando_a') }} {{
                alumnoSeleccionado.nombreCompleto }}</v-card-title>
            <v-progress-linear color="primary" :model-value="progress" :height="12"></v-progress-linear>
            <HelperCarregant v-if="loading" :missatge="t('Cargando') + ' ' + t('datos')" />
            <v-card-text v-if="!loading" style="overflow-y: auto;">
                <template v-if="!feedback">
                    <v-window disabled v-model="onboarding">
                        <template v-if="!alumnoSeleccionado.nombreCompleto && selectedOptionVista === 'docente'">
                            <v-card class="mx-4">
                                <v-toolbar flat>
                                    <v-toolbar-title>{{ t('Alumnos') }}</v-toolbar-title>
                                </v-toolbar>
                                <v-text-field class="mx-4 mt-4" v-model="search" label="Cerca"
                                    prepend-inner-icon="mdi-magnify" single-line variant="outlined"
                                    hide-details></v-text-field>
                                <v-data-table-virtual :headers="headers" :items="alumnos_total" :search="search"
                                    :loading="loading2" @click="seleccionarAlumno(alumno)">
                                    <template v-slot:loading>
                                        <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
                                    </template>
                                </v-data-table-virtual>
                            </v-card>
                        </template>
                        <template v-else>
                            <template v-if="cuestionario.secuencias">
                                <div v-for="secuencia in cuestionario.secuencias" :key="secuencia.id">
                                    <v-card class="mb-4">
                                        <v-card-title>{{ secuencia.plano.nombre }} ({{ secuencia.posicion
                                            }})</v-card-title>
                                        <v-card-text>
                                            <v-row>
                                                <v-col cols="12" sm="6">
                                                    <v-text-field v-model="secuencia.tiempo_inicial"
                                                        label="Tiempo Inicial (mm:ss)" type="text"
                                                        @input="formatTime($event, 'tiempo_inicial', secuencia)">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6">
                                                    <v-text-field v-model="secuencia.tiempo_final"
                                                        label="Tiempo Final (mm:ss)" type="text"
                                                        @input="formatTime($event, 'tiempo_final', secuencia)">
                                                    </v-text-field>
                                                </v-col>
                                                <v-list class="py-0" v-for="criterio in secuencia.plano.criterios"
                                                    :key="criterio.id">
                                                    <v-checkbox v-model="criterio.seleccionado"
                                                        :label="criterio.descripcion">
                                                    </v-checkbox>
                                                </v-list>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </div>
                                <v-btn @click="submitEvaluation">{{ t('Guardar') }}</v-btn>
                            </template>
                            <template v-if="!cuestionario.secuencias">
                                <v-window-item v-for="pregunta in cuestionario.preguntas" :key="pregunta.id">
                                    <div v-if="pregunta.tipo === 'ordenar'">
                                        <PreguntaOrdenar :pregunta="pregunta" @respuesta-enviada="handleRespuesta" />
                                    </div>
                                    <div v-if="pregunta.tipo === 'relacionar'">
                                        <PreguntaRelacionar :pregunta="pregunta" @respuesta-enviada="handleRespuesta" />
                                    </div>
                                    <div v-if="pregunta.tipo === 'relacionar_cols'">
                                        <PreguntaRelacionarCols :pregunta="pregunta"
                                            @respuesta-enviada="handleRespuesta" />
                                    </div>
                                    <div v-if="pregunta.tipo === 'multiple_choice'">
                                        <PreguntaMultipleChoice :pregunta="pregunta"
                                            @respuesta-enviada="handleRespuesta" />
                                    </div>
                                </v-window-item>
                            </template>
                        </template>
                    </v-window>
                </template>
                <v-window class="d-flex justify-center align-center" v-if="feedback">
                    <v-container style="height: 400px;">
                        <v-row align-content="center" class="fill-height" justify="center">
                            <v-rating size="72" v-model="rating" length="3" :active-color="ratingDetails.color"
                                color="grey" readonly>
                            </v-rating>
                            <v-col class="text-subtitle-1 text-center" cols="12">
                                {{ ratingDetails.message }}
                            </v-col>
                            <v-btn @click="cerrarOverlay" color="primary">
                                {{ t('Continua') }}
                            </v-btn>
                        </v-row>
                    </v-container>
                </v-window>
            </v-card-text>
        </v-card>
    </v-dialog>
    <!-- Diálogo para crear un nuevo cuestionario -->
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>
                    {{ dialogType === 'crear' ? t('Crear') : t('Editar') }} {{ t('cuestionario') }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn :disabled="loading" @click="dialogType === 'crear' ? crearCuestionario() : editarCuestionario()"
                    prepend-icon="mdi-content-save">
                    {{ dialogType === 'crear' ? t('Crear') : t('Guardar') }}
                </v-btn>
                <v-btn icon @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <HelperCarregant v-if="loading" :missatge="t('Creando') + ' ' + t('cuestionario')" />
                <template v-if="!loading">
                    <v-text-field v-model="nuevoCuestionario.titulo" :label="t('Título')" required></v-text-field>
                    <v-text-field type="number" v-model="nuevoCuestionario.num_preguntas"
                        :label="t('Número_de') + ' ' + t('preguntas')" required></v-text-field>
                    <v-expansion-panels class="mt-4">
                        <v-expansion-panel :title="t('Opciones_adicionales')">
                            <v-expansion-panel-text>
                                <v-select v-model="nuevoCuestionario.tipo_reto" :items="['normal', 'contrareloj']"
                                    :label="t('Tipo_de_reto')" required></v-select>
                                <v-btn v-if="dialogType === 'editar'" color="red" text prepend-icon="mdi-delete"
                                    @click="confirmarEliminacion">
                                    {{ t('Eliminar') }}
                                </v-btn>
                            </v-expansion-panel-text>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </template>
            </v-card-text>
        </v-card>
    </v-dialog>
    <!-- Diálogo para el CRUD de preguntas -->
    <v-dialog v-model="preguntasDialog" persistent>
        <AdminPreguntes :cuestionario="cuestionario" :loading="loading" @cerrar-preguntas="closePreguntasDialog"
            @detalles-cuestionario="openDialog('editar')" @actualizar-preguntas="fetchCuestionario" />
    </v-dialog>
</template>

<script setup>
import { ref, reactive, computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { axios } from '../axios';
import PreguntaOrdenar from './PreguntaOrdenar.vue';
import PreguntaRelacionar from './PreguntaRelacionar.vue';
import PreguntaRelacionarCols from './PreguntaRelacionarCols.vue';
import PreguntaMultipleChoice from './PreguntaMultipleChoice.vue';
import AdminPreguntes from './AdminPreguntes.vue';
import HelperCarregant from './HelperCarregant.vue';
import { useStore } from 'vuex';

const { t } = useI18n();

const props = defineProps({
    IDcuestionario: String,
    IDleccion: String,
    cursoId: String,
    selectedOption: String,
    selectedOptionVista: String,
    navbarra: Boolean,
    nota_usuario: {
        type: Object,
        default: () => ({}),
    },
});

const emit = defineEmits(['respuesta-enviada', 'nuevo-cuestionario', 'eliminar-cuestionario']);

const store = useStore();

const cuestionario = reactive({ preguntas: [] });
const overlay = ref(false);
const onboarding = ref(0);
const progress = ref(5);
const loading = ref(false);
const loading2 = ref(false);
const preguntasIDs = ref([]);
const respuestas = ref({});
const resultat = ref([]);
const feedback = ref(false);
const rating = ref(0);
const dialog = ref(false);
const preguntasDialog = ref(false);
const dialogType = ref('');
const nuevoCuestionario = reactive({
    leccion: null,
    titulo: '',
    tipo_reto: 'normal',
    num_preguntas: 10,
    tiempo_contrareloj: 60,
    bonus: 5,
    penalizacion: 10,
});
const snackbarText = ref('');
const headers = ref([
    { title: t('Nombre'), key: 'nombre' },
    { title: t('Apellidos'), key: 'apellidos' },
]);
const alumnos_total = ref([]);
const search = ref('');
const alumnoSeleccionado = ref({ nombreCompleto: '', });

const seleccionarAlumno = (alumno) => {
    alumnoSeleccionado.value.nombreCompleto = `${alumno.first_name} ${alumno.last_name}`;
    alumnoSeleccionado.value.id = alumno.id;
    dialog.value = true;
};

const formatTime = (event, field, secuencia) => {
    let value = event.target.value;

    // Remove all non-digit characters
    value = value.replace(/[^0-9]/g, '');

    // Ensure value is not longer than 4 characters (mmss)
    if (value.length > 4) {
        value = value.substr(0, 4);
    }

    // Add colon after two digits
    if (value.length > 2) {
        value = value.substr(0, 2) + ':' + value.substr(2);
    }

    // Update the corresponding field in secuencia
    secuencia[field] = value;
};

const isValidTimeFormat = (time) => {
  const timePattern = /^\d{2}:\d{2}$/;
  if (!timePattern.test(time)) {
    return false;
  }

  // Split the time into minutes and seconds
  const [minutes, seconds] = time.split(':').map(Number);

  // Check if minutes and seconds are within the range
  return minutes <= 59 && seconds <= 59;
};

const submitEvaluation = async () => {
    snackbarText.value = '';

  // Validate time format for each secuencia
  for (let secuencia of respuestas.value[0].secuencias) {
    if (!isValidTimeFormat(secuencia.tiempo_inicial) || !isValidTimeFormat(secuencia.tiempo_final)) {
        snackbarText.value = `Error en ${secuencia.plano.nombre} (${secuencia.posicion}): Algún campo de tiempo está vacío o tiene un formato incorrecto.`;
      return; // Stop the submission process and show the error
    }
  }

  // Construct the data object to send
  const datosParaEnviar = {
    respuestas_docente: respuestas.value,
    alumno: alumnoSeleccionado.value.id,
    curso: props.cursoId,
  };
  try {
    const response = await axios.post(`enviar_respuestas_docente/`, datosParaEnviar);

    // Handle the server response
    if (response.data.success) {
      respuestas.value = {}; // Clean up on finish
    } else {
        snackbarText.value = response.data.message;
    }
  } catch (error) {
    snackbarText.value = error.response ? error.response.data.message : error.message;
  }
};
const ratingDetails = computed(() => {
    let details = {
        color: 'grey', // Default color
        message: t('Inténtalo_de_nuevo'),
    };

    switch (rating.value) {
        case 3:
            details.color = 'amber-accent-2';
            details.message = t('Excelente');
            break;
        case 2:
            details.color = 'blue-grey-lighten-2';
            details.message = t('Buen_esfuerzo');
            break;
        case 1:
            details.color = 'deep-orange-lighten-2';
            details.message = t('Aceptable_aunque_puedes_mejorar');
            break;
    }
    return details;
});

const starColor = computed(() => {
    if (!props.nota_usuario || props.nota_usuario.nota === null) {
        return 'grey'; // Default color if no grade
    }
    const nota = Math.round(props.nota_usuario.nota);
    if (nota >= 9) {
        return 'amber-accent-2'; // Excelente
    } else if (nota >= 8) {
        return 'blue-grey-lighten-2'; // Bueno
    } else if (nota >= 7) {
        return 'deep-orange-lighten-2'; // Aceptable
    } else {
        return 'red'; // Mejorable
    }
});

const mdiIcon = computed(() => {
    if (!props.nota_usuario || props.nota_usuario.nota === null) {
        return 'mdi-progress-question'; // Default icon if no grade
    } else {
        const nota = Math.round(props.nota_usuario.nota);
        if (nota >= 10) {
            return 'mdi-star-shooting';
        } else if (nota < 7) {
            return 'mdi-close-circle-outline';
        } else {
            return 'mdi-star';
        }
    }
});

const fetchAlumnes = async () => {
    loading.value = true;
    try {
        const response = await axios.get(`curso/${props.cursoId}/alumnos_total/`);
        alumnos_total.value = response.data;
        loading.value = false;
    } catch (error) {
        if (error.response && error.response.status === 403) {
            console.error("Error obtaining students:", error);
        } else {
            // Handle other types of errors
            alert("An error occurred while fetching students.");
        }
        loading.value = false;
    }
};

function openPreguntasDialog() {
    fetchCuestionario();
    preguntasDialog.value = true;
}

function closePreguntasDialog() {
    preguntasDialog.value = false;
}

async function fetchCuestionario() {
    if (!props.IDcuestionario) {
        console.log('No se proporcionó ID de cuestionario.');
        return; // Exit function if no valid ID
    }
    feedback.value = false;
    loading.value = true;
    try {
        const response = await axios.get(`cuestionario/${props.IDcuestionario}/`);
        Object.assign(cuestionario, response.data);
        // Extract question IDs
        preguntasIDs.value = cuestionario.preguntas.map((pregunta) => pregunta.id);
        loading.value = false;
        progress.value = (1 / cuestionario.preguntas.length) * 100;
    } catch (error) {
        cerrarOverlay();
        store.dispatch('triggerSnackbar', { message: error, color: 'error' });
        loading.value = false;
    }
}

async function evaluarCuestionario() {
    loading.value = true;
    feedback.value = true;
    const datosParaEnviar = {
        IDcuestionario: props.IDcuestionario,
        preguntasIDs: preguntasIDs.value,
    };
    try {
        const response = await axios.post(`evaluar/`, datosParaEnviar);
        resultat.value = response.data;
        rating.value = Math.round(resultat.value.nota) - 7;
        loading.value = false;
        emit('respuesta-enviada');
    } catch (error) {
        if (error.response && error.response.status === 403) {
            // If error is 403 Forbidden, user has no access
        }
        store.dispatch('triggerSnackbar', { message: error, color: 'error' });
        cerrarOverlay();
        loading.value = false;
    }
}

function handleRespuesta() {
    next();
}

function next() {
    if (onboarding.value + 1 >= cuestionario.preguntas.length) {
        // Close the overlay and reset onboarding to the start (or handle it differently if needed)
        evaluarCuestionario();
    } else {
        onboarding.value = onboarding.value + 1;
        progress.value = ((onboarding.value + 1) / cuestionario.preguntas.length) * 100;
    }
}

function accederCuestionario() {
    fetchCuestionario();
    feedback.value = false;
    onboarding.value = 0;
    overlay.value = true;
}

function cerrarOverlay() {
    overlay.value = false;
    progress.value = 5;
}

async function editarCuestionario() {
    loading.value = true;
    nuevoCuestionario.leccion = props.IDleccion;
    try {
        const response = await axios.put(`cuestionarios/${props.IDcuestionario}`, nuevoCuestionario);
        Object.assign(cuestionario, response.data);
        snackbarText.value = t('Cuestionario') + ' ' + t('actualizado_con_éxito');
        store.dispatch('triggerSnackbar', { message: snackbarText.value, color: 'success' });
        dialog.value = false; // Close dialog after editing
        loading.value = false;
    } catch (error) {
        if (error.response && error.response.data && typeof error.response.data === 'object') {
            // Extract error messages from the response body
            const errors = error.response.data;
            let errorMessage = '';
            // Iterate over each key in the errors object to build the message
            Object.keys(errors).forEach((key) => {
                errorMessage += `${key}: ${errors[key].join(' ')}\n`; // Join to convert possible arrays into strings
            });
            snackbarText.value = errorMessage;
        } else {
            // If the error response is not in the expected format
            snackbarText.value = error;
        }
        store.dispatch('triggerSnackbar', { message: snackbarText.value, color: 'error' });
        loading.value = false;
    }
}

function openDialog(type) {
    dialogType.value = type;
    dialog.value = true;
    if (type === 'editar' && cuestionario) {
        nuevoCuestionario.titulo = cuestionario.titulo;
        nuevoCuestionario.tipo_reto = cuestionario.tipo_reto;
        nuevoCuestionario.num_preguntas = cuestionario.num_preguntas;
        nuevoCuestionario.tiempo_contrareloj =
            cuestionario.tiempo_contrareloj || nuevoCuestionario.tiempo_contrareloj;
        nuevoCuestionario.bonus = cuestionario.bonus || nuevoCuestionario.bonus;
        nuevoCuestionario.penalizacion = cuestionario.penalizacion || nuevoCuestionario.penalizacion;
    } else {
        nuevoCuestionario.leccion = props.IDleccion;
        nuevoCuestionario.titulo = '';
        nuevoCuestionario.tipo_reto = 'normal';
        nuevoCuestionario.num_preguntas = 10;
        nuevoCuestionario.tiempo_contrareloj = 90;
        nuevoCuestionario.bonus = 5;
        nuevoCuestionario.penalizacion = 10;
    }
}

async function crearCuestionario() {
    loading.value = true;
    try {
        const response = await axios.post(`cuestionarios/`, nuevoCuestionario);
        Object.assign(cuestionario, response.data);
        emit('nuevo-cuestionario', { data: response.data.id });
        store.dispatch('triggerSnackbar', {
            message: t('Cuestionario') + ' ' + t('creado_con_éxito'),
            color: 'success',
        });
        loading.value = false;
        openPreguntasDialog();
    } catch (error) {
        if (error.response && error.response.data && typeof error.response.data === 'object') {
            // Extract error messages from the response body
            const errors = error.response.data;
            let errorMessage = '';
            // Iterate over each key in the errors object to build the message
            Object.keys(errors).forEach((key) => {
                errorMessage += `${key}: ${errors[key].join(' ')}\n`; // Join to convert possible arrays into strings
            });
            snackbarText.value = errorMessage;
        } else {
            // If the error response is not in the expected format
            snackbarText.value = error;
        }
        store.dispatch('triggerSnackbar', { message: snackbarText.value, color: 'error' });
        loading.value = false;
    }
    dialog.value = false;
}

function confirmarEliminacion() {
    if (
        confirm(
            '¿Estás seguro de que quieres eliminar este cuestionario? Esta acción no se puede deshacer.'
        )
    ) {
        eliminarCuestionario();
    }
}

async function eliminarCuestionario() {
    loading.value = true;
    try {
        await axios.delete(`cuestionarios/${props.IDcuestionario}`);
        loading.value = false;
        snackbarText.value = t('Cuestionario') + ' ' + t('eliminado_con_éxito');
        store.dispatch('triggerSnackbar', { message: snackbarText.value, color: 'success' });
        emit('eliminar-cuestionario');
        closePreguntasDialog();
    } catch (error) {
        store.dispatch('triggerSnackbar', { message: error, color: 'error' });
        loading.value = false;
    }
    dialog.value = false;
}
onMounted(() => {
    console.log(props.selectedOptionVista)
    if (props.selectedOptionVista == 'docente') {
        fetchAlumnes();
    }
});
</script>