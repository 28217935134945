<template>
  <FullCalendar ref="fullCalendarRef" :options="calendarOptions" />
  <v-card variant="text">
    <v-text-field class="mx-4 mt-4" v-model="search" label="Cerca" prepend-inner-icon="mdi-magnify" single-line
      variant="outlined" hide-details></v-text-field>
    <v-data-table-virtual :headers="headers" :items="timeSlots" :search="search" :loading="loading">
      <template v-slot:loading>
        <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
      </template>
    </v-data-table-virtual>
  </v-card>

  <v-dialog v-model="showDialog">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>{{ currentEvent.title }}</v-toolbar-title>
        <v-btn icon @click="showDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-list lines="two">
        <v-list-item :title="t('Docentes')" :subtitle="currentEvent.emailsDocentes"></v-list-item>
        <v-list-item :title="t('Alumnos')" :subtitle="currentEvent.emailsAlumnos"></v-list-item>
      </v-list>
      <v-card-actions>
        <v-btn color="primary" text :to="`/curso/${currentEvent.curso}`">{{ t('Contenido de la actividad') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { axios } from '../axios';
import { useI18n } from 'vue-i18n';
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { ref, onMounted, watch, computed } from 'vue';
import { format } from 'date-fns'; // Importa la función format

const { t, locale } = useI18n();
const fullCalendarRef = ref(null);
const selectedDate = ref(new Date());
const timeSlots = ref([]);
const loading = ref(false);
const showDialog = ref(false);
const search = ref('');
const calendarPlugins = [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]; // Include all necessary plugins
const currentEvent = ref({
  titulo: '',
  emailsAlumnos: '',
  emailsDocentes: '',
  curso: '',
});

const headers = ref([
  { title: t('Actividad'), key: 'extendedProps.curso_titulo' },
  { title: t('Fecha_de_inicio'), key: 'fechaInicio' },
  { title: t('Hora_de_inicio'), key: 'horaInicio' },
  { title: t('Fecha_de_fin'), key: 'fechaFin' },
  { title: t('Hora_de_fin'), key: 'horaFin' },
  { title: t('Alumnos'), key: 'extendedProps.emailsAlumnos' },
  { title: t('Docentes'), key: 'extendedProps.emailsDocentes' },
  { title: t('Simulador'), key: 'nombreSimulador' }
]);

const calendarOptions = computed(() => {
  return {
    plugins: calendarPlugins,
    initialView: 'dayGridMonth',
    customButtons: {
      customPrev: {
        text: t('anterior'),
        click: function () {
          if (fullCalendarRef.value && fullCalendarRef.value.getApi()) {
            fullCalendarRef.value.getApi().prev(); // Mueve el calendario a la nueva fecha
            selectedDate.value = fullCalendarRef.value.getApi().getDate();
          }
        }
      },
      customNext: {
        text: t('siguiente'),
        click: function () {
          if (fullCalendarRef.value && fullCalendarRef.value.getApi()) {
            fullCalendarRef.value.getApi().next(); // Mueve el calendario a la nueva fecha
            selectedDate.value = fullCalendarRef.value.getApi().getDate();
          }
        }
      },
    },
    headerToolbar: {
      left: 'customPrev,customNext',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
    },
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    weekends: true,
    events: timeSlots.value,
    locale: locale.value, // Dynamically set the locale based on Vue I18n
    buttonText: {
      month: t('mes'),
      week: t('semana'),
      day: t('día'),
      list: t('listado'),
    },
    select: handleDateSelect,
    eventClick: handleEventClick
  };
});

function handleDateSelect(selectInfo) {
  selectedDate.value = selectInfo.start;
  fullCalendarRef.value.getApi().changeView('timeGridDay');
}

function handleEventClick(clickInfo) {
  currentEvent.value.title = clickInfo.event.title;
  currentEvent.value.curso = clickInfo.event.extendedProps.curso;
  currentEvent.value.emailsAlumnos = clickInfo.event.extendedProps.emailsAlumnos;
  currentEvent.value.emailsDocentes = clickInfo.event.extendedProps.emailsDocentes;
  showDialog.value = true;
}

watch(selectedDate, (newValue) => {
  if (fullCalendarRef.value && fullCalendarRef.value.getApi()) {
    fullCalendarRef.value.getApi().gotoDate(newValue); // Mueve el calendario a la nueva fecha
  }
  fetchTimeSlots(); // Suponiendo que también necesitas cargar eventos específicos del mes
});


const formatDates = (slots) => {
  return slots.map(slot => ({
    fechaInicio: format(new Date(slot.inicio), 'yyyy-MM-dd'),
    horaInicio: format(new Date(slot.inicio), 'HH:mm'),
    fechaFin: format(new Date(slot.fin), 'dd/MM/yyyy'),
    horaFin: format(new Date(slot.fin), 'HH:mm'),

    nombreSimulador: slot.simulador.nombre,
    title: slot.simulador.nombre + ' (' + slot.curso_titulo + ')',
    start: slot.inicio,
    end: slot.fin,
    extendedProps: {
      emailsAlumnos: slot.alumnos.map(alumno => alumno.email).join(', '),
      emailsDocentes: slot.docentes.map(docente => docente.email).join(', '),
      curso: slot.curso,
      curso_titulo: slot.curso_titulo,
    }
  }));
};

const fetchTimeSlots = async () => {
  loading.value = true;
  try {
    const response = await axios.get(`reserved-time-slots/?month=${selectedDate.value.getMonth() + 1}&year=${selectedDate.value.getFullYear()}`);
    timeSlots.value = response.data;
    timeSlots.value = formatDates(timeSlots.value);
  } catch (error) {
    console.error("There was an error fetching the time slots:", error);
  } finally {
    loading.value = false;
  }
};

onMounted(() => {
  fetchTimeSlots();
  setTimeout(() => {
    fullCalendarRef.value.getApi().render();
  });
});
</script>