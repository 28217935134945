<template>
    <v-btn block class="text-wrap" color="green" style="word-break: break-word" @click="showQrCode" prepend-icon="mdi-qrcode">{{ t('Acceso') }} ({{
        selectedOptionVista }})</v-btn>
    <!-- Modal para el QR -->
    <v-dialog v-model="showQrModal" persistent max-width="300">
        <v-card>
            <div id="qr-code-container" ref="qrCodeContainer">
                <v-card-title class="text-center mt-3">{{ t('Código_QR_de_acceso') }}</v-card-title>
                <v-card-text class="text-center">
                    <vue-qrcode :value="currentQrValue"></vue-qrcode>
                    <div>{{ curso.titulo }}</div>
                    <div>{{ t('Acceder_al_centro_sin_reserva') }}</div>
                </v-card-text>
            </div>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" text @click="downloadQr">{{ t('Descargar') }}</v-btn>
                <v-btn color="primary" text @click="closeQrModal">{{ t('Cerrar') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup>
import html2canvas from 'html2canvas';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import { axios } from '../axios';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';

const props = defineProps({
    curso: {
        type: Object
    },
    selectedOptionVista: String,
});

const { t } = useI18n();
const showQrModal = ref(false);
const currentQrValue = ref('');

function showQrCode() {
    axios.post('/generate_qr/', {
        afos: props.curso.afos,
        selectedOption: props.selectedOptionVista
    }).then(response => {
        currentQrValue.value = response.data.combined_codiqr;
        showQrModal.value = true;
    }).catch(error => {
        console.error('Error al generar QR: ', error);
    });
}

function closeQrModal() {
    showQrModal.value = false;
}

function downloadQr() {
    html2canvas(this.$refs.qrCodeContainer.value).then(canvas => {
        let link = document.createElement('a');
        link.download = 'qr-code.png';
        link.href = canvas.toDataURL('image/png');
        link.click();
    }).catch(err => {
        console.error('Error capturing QR: ', err);
    });
}
</script>