<template>
  <v-card variant="text">
    <v-card-title class="text-wrap" style="word-break: break-word">
      {{ pregunta.texto }}
    </v-card-title>
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="6">
          <v-list :disabled="haRespondido">
            <v-list-item v-for="(opcion, index) in pregunta.opciones" :key="index" :value="opcion.id"
              :base-color="haRespondido ? (esCorrecta(opcion.id) ? 'green' : 'error') : (esCorrecta(opcion.id) ? 'green' : 'primary')"
              :disabled="esCorrecta(opcion.id)" @click="seleccion1 = opcion.id">
              <v-chip class="text-wrap" style="word-break: break-word">{{ opcion.texto
                }}</v-chip>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="6">
          <v-list :disabled="haRespondido">
            <v-list-item v-for="(opcion, index) in opcionesDesordenadas" :key="index" :value="opcion.id"
              :base-color="haRespondido ? (esCorrecta(opcion.id) ? 'green' : 'error') : (esCorrecta(opcion.id) ? 'green' : 'primary')"
              :disabled="esCorrecta(opcion.id)" @click="seleccion2 = opcion.id">
              <v-chip class="text-wrap" style="word-break: break-word">{{ opcion.texto2
                }}</v-chip>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <v-btn block v-if="haRespondido" :disabled="loading" color="primary" class="mt-3" @click="next">
    {{ t('Continua') }}
  </v-btn>
</template>

<script setup>
import { ref, watch } from 'vue';
import { axios } from '../axios';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  pregunta: {
    type: Object,
    default: () => ({})
  }
});
const { t } = useI18n();
const emits = defineEmits(['respuesta-enviada']);

const seleccion1 = ref(null);
const seleccion2 = ref(null);
const haRespondido = ref(false);
const loading = ref(false);

const opcionesDesordenadas = ref([]);
const opcionesCorrectas = ref(new Set());

function desordenarArray(array) {
  return array.slice().sort(() => Math.random() - 0.5);
}

function esCorrecta(id) {
  return opcionesCorrectas.value.has(id);
}

watch(() => props.pregunta.opciones, (nuevasOpciones) => {
  opcionesDesordenadas.value = desordenarArray(nuevasOpciones);
}, { immediate: true });

watch([seleccion1, seleccion2], ([nuevaSeleccion1, nuevaSeleccion2]) => {
  if (nuevaSeleccion1 !== null && nuevaSeleccion2 !== null) {
    realizarComprobacion();
  }
});

async function realizarComprobacion() {
  if (seleccion1.value === seleccion2.value) {
    console.log("Las selecciones coinciden.");
    console.log(seleccion1.value);
    console.log(opcionesCorrectas.value);
    opcionesCorrectas.value.add(seleccion1.value);
    if (opcionesCorrectas.value.size === props.pregunta.opciones.length) {
      enviarCuestionario();
    }
    seleccion1.value = null;
    seleccion2.value = null;
  } else {
    haRespondido.value = true;
    enviarCuestionario();
  }
}

async function enviarCuestionario() {
  loading.value = true;
  const datosParaEnviar = {
    opcion_respuesta_ids: [...opcionesCorrectas.value] // Convertir Set a Array
  };
  try {
    const respuesta = await axios.post(`enviar_respuestas/`, datosParaEnviar);
    if (respuesta.data.success) {
      if (!haRespondido.value) { // Totes les respostes eren correctes, no s'ha activat haRespondido, no cal feedback
        next();
      }
    } else {
      console.error('Error al enviar respuestas:', respuesta.data.message);
    }
    loading.value = false;
  } catch (error) {
    console.error('Error al realizar la solicitud:', error);
    loading.value = false;
  }
}

function next() {
  emits('respuesta-enviada', props.pregunta);
}
</script>